var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c(
      "div",
      { staticClass: "flex justify-center py-3 mb-3 border-b border-blue-500" },
      [
        _c("h1", {
          staticClass: "font-semibold",
          domProps: {
            textContent: _vm._s(
              _vm.$t("the_not_authorized.label_not_authorized")
            )
          }
        })
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "flex-grow flex flex-col items-center justify-center mx-16 mb-8"
      },
      [
        _c(
          "p",
          {
            staticClass:
              "rounded-full bg-red-500 text-white text-6xl w-24 h-24\n      text-center pb-1 mb-8 flex justify-center items-center"
          },
          [_vm._v("\n      x\n    ")]
        ),
        _c("p", {
          staticClass: "mx-5 text-center leading-snug mb-8 w-full",
          domProps: {
            textContent: _vm._s(
              _vm.$t("the_not_authorized.label_not_authorized_info")
            )
          }
        }),
        _c("router-link", {
          staticClass:
            "text-blue-500 flex flex-row items-center\n        p-2 rounded-lg mx-3 hover:text-blue-600 focus:text-blue-600",
          attrs: { to: { name: "home" } },
          domProps: {
            textContent: _vm._s(
              _vm.$t("the_not_authorized.button_back_to_home")
            )
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }