<template>
  <div class="flex flex-col">
    <div class="flex justify-center py-3 mb-3 border-b border-blue-500">
      <h1
        v-text="$t('the_not_authorized.label_not_authorized')"
        class="font-semibold"></h1>
    </div>
    <div class="flex-grow flex flex-col items-center justify-center mx-16 mb-8">
      <p
        class="rounded-full bg-red-500 text-white text-6xl w-24 h-24
        text-center pb-1 mb-8 flex justify-center items-center">
        x
      </p>
      <p
        v-text="$t('the_not_authorized.label_not_authorized_info')"
        class="mx-5 text-center leading-snug mb-8 w-full"
      ></p>
      <router-link
        :to="{ name: 'home' }"
        class="text-blue-500 flex flex-row items-center
          p-2 rounded-lg mx-3 hover:text-blue-600 focus:text-blue-600"
        v-text="$t('the_not_authorized.button_back_to_home')"
      >
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TheNotAuthorized',
  };
</script>
